import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
        if (!condition(authUser)) {
          if (!!authUser && !!authUser.uid) {
            // user is logged in
            if (this.props.location.path !== ROUTES.HOME) {
              this.props.history.push(ROUTES.HOME);
            }
          } else {
            let currentPath =
              this.props.location && this.props.location.pathname;
            let searchParams = new URLSearchParams();
            searchParams.set("redirectUrl", currentPath);
            this.props.history.push({
              pathname: ROUTES.SIGN_IN,
              search: `?${searchParams.toString()}`
            });
          }
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            condition(authUser) ? (
              <Component {...this.props} user={authUser} />
            ) : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(
    withRouter,
    withFirebase
  )(WithAuthorization);
};

export default withAuthorization;
