import { cloneDeep } from "lodash";
export const styles = theme => {
  return {
    root: {
      flexGrow: 1,
      padding: theme.spacing.unit + "px"
    },
    spinner: {
      flexGrow: 1,
      alignItems: "center",
      height: "90vh"
    },
    formControl: {
      margin: theme.spacing.unit,
      marginLeft: 0,
      minWidth: 100
    }
  };
};

export const convertToArray = obj => {
  let o = cloneDeep(obj);
  if (!o) {
    return [];
  }
  return Object.keys(o).map(id => {
    return {
      id,
      ...o[id]
    };
  });
};

export default {
  convertToArray,
  styles
};
