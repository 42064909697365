import React from "react";
import { withFirebase } from "../Firebase";
import { Grid, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import If from "../If";

class PlayerSimple extends React.Component {
  state = {
    loading: true
  };
  componentDidMount() {
    if (this.props.uid) {
      this.props.firebase
        .user(this.props.uid)
        .child("name")
        .on("value", snapshot => {
          let usersName = snapshot.val();
          if (!!usersName) {
            this.setState({
              name: usersName
            });
          }
        });
    }
  }

  render() {
    return (
      <Grid container>
        <If condition={this.props.showCheck}>
          <CheckIcon style={{ marginRight: "1rem" }} />
        </If>
        <Typography variant="body1">{this.state.name} </Typography>
      </Grid>
    );
  }
}

export default withFirebase(PlayerSimple);
