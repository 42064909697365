import React from "react";

import { AuthUserContext, withAuthorization } from "../Session";
import UserProfileContext from "./context";
import { DateTime } from "luxon";

const withProfile = condition => Component => {
  class WithProfile extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        profile: null
      };
    }

    componentDidMount() {
      this.props.firebase
        .user(this.props.authUser.uid)
        .on("value", snapshot => {
          let profile = snapshot.val();
          if (!condition(profile, this.props.authUser)) {
            this.props.history.goBack();
          } else {
            this.setState({ profile });
          }
        });
    }

    componentWillUnmount() {
      this.props.firebase.user(this.props.authUser.uid).off();
    }

    updateProfile = profileData => {
      return this.props.firebase.user(this.props.authUser.uid).update({
        ...profileData,
        updatedAt: DateTime.local().toMillis()
      });
    };

    render() {
      return (
        <UserProfileContext.Provider value={this.state.profile}>
          {condition(this.state.profile, this.props.authUser) ? (
            <Component
              {...this.props}
              profile={this.state.profile}
              updateProfile={this.updateProfile}
              user={this.props.authUser}
            />
          ) : null}
        </UserProfileContext.Provider>
      );
    }
  }

  const authCondition = authUser => {
    return !!authUser && !!authUser.uid;
  };
  return withAuthorization(authCondition)(props => {
    return (
      <AuthUserContext.Consumer>
        {authUser => {
          return <WithProfile authUser={authUser} {...props} />;
        }}
      </AuthUserContext.Consumer>
    );
  });
};

export default withProfile;
