import React from "react";
import { DateTime } from "luxon";
import styled from "styled-components";

import withProfile from "./withProfile";
import If from "../If";

import { AuthUserContext } from "../Session";
import UserProfileContext from "./context";
import { PasswordForgetForm } from "../PasswordForget";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  withStyles
} from "@material-ui/core";
import { capitalize } from "lodash";
import { styles } from "../../utils";

const Ul = styled.ul`
  margin-top: 0.5rem;
  padding-left: 0.5rem;

  & li {
    list-style: none;
    margin-bottom: 0.75rem;

    & ul {
      padding-left: 1.5rem;
    }
  }
`;

const AccountPage = props => {
  const displayFields = [
    {
      field: "email",
      formatter: (value, field) => {
        return value;
      }
    },
    {
      field: "name",
      formatter: (value, field) => {
        return value;
      }
    },
    {
      field: "notifications",
      formatter: (value, field) => {
        return !!value ? (
          <Ul>
            {Object.keys(value).map(notificationType => {
              return (
                <li key={notificationType}>
                  {`${notificationType} : `}
                  {!!value[notificationType] ? "on" : "off"}
                </li>
              );
            })}
          </Ul>
        ) : null;
      }
    },
    {
      field: "phoneNumber",
      label: "Phone Number"
    },
    {
      field: "createdAt",
      label: "Created at",
      formatter: (value, field) => {
        return !!value ? DateTime.fromMillis(value).toFormat("ff") : null;
      }
    },
    {
      field: "updatedAt",
      label: "Last profile update",
      formatter: (value, field) => {
        return !!value ? DateTime.fromMillis(value).toFormat("ff") : null;
      }
    }
  ];
  const { classes } = props;
  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <Grid container>
          <UserProfileContext.Consumer>
            {profile => {
              if (!!profile) {
                return (
                  <Grid container justify="center" className={classes.root}>
                    <Grid item xs={12}>
                      <Typography variant="h5" color="inherit" gutterBottom>
                        Account Page
                      </Typography>
                      <Card>
                        <CardContent>
                          <Ul>
                            {displayFields.map((displayObj, key) => {
                              let formatFunction =
                                typeof displayObj.formatter === "function"
                                  ? displayObj.formatter
                                  : value => value;
                              let value = profile && profile[displayObj.field];
                              let displayValue = formatFunction(
                                value,
                                displayObj.field
                              );

                              return (
                                <If key={key} condition={!!displayValue}>
                                  <li key={key}>
                                    {`${
                                      !!displayObj.label
                                        ? capitalize(displayObj.label)
                                        : capitalize(displayObj.field)
                                    } : `}
                                    {displayValue}
                                  </li>
                                </If>
                              );
                            })}
                          </Ul>
                          <PasswordForgetForm />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                );
              }
            }}
          </UserProfileContext.Consumer>
        </Grid>
      )}
    </AuthUserContext.Consumer>
  );
};

const condition = (profile, authUser) => !!authUser;

export default withProfile(condition)(withStyles(styles)(AccountPage));

export { withProfile };
