import { CalendarIcon } from "react-calendar-icon";
import { DateTime } from "luxon";
import {
  Grid,
  Card,
  Typography,
  CardContent,
  CircularProgress,
  IconButton,
  List,
  ListItem
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import React from "react";

import { GameActions } from ".";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import If from "../If";
import Player from "../Player";

class GameDetails extends React.Component {
  state = {
    id: null,
    players: null,
    playersArr: [],
    loading: true,
    canEdit: false,
    snackBar: {
      open: false
    },
    deleted: false,
    invites: [],
    calendarAnchorElm: null
  };
  componentDidMount() {
    this.loadGame();
  }

  loadGame() {
    this.setState({
      loading: true
    });
    if (this.props.match.params && this.props.match.params.id) {
      this.props.firebase
        .gameById(this.props.match.params.id)
        .on("value", snapshot => {
          var game = snapshot.val();
          if (!!game) {
            let playersArr = !!game.players
              ? Object.keys(game.players).filter(uid => {
                  return !!game.players[uid];
                })
              : [];

            this.setState({
              loading: false,
              id: snapshot.key,
              ...game,
              playersArr,
              totalPlayers: parseInt(game.totalPlayers),
              canEdit: this.props.user && game.createdBy === this.props.user.uid
            });
          } else {
            this.setState({
              loading: false
            });
          }
        });
    } else {
      throw new Error("Couldnt find id in params");
    }
  }

  render() {
    let currentPlayers = this.state.playersArr;
    let startDate = !!this.state.startTime
      ? DateTime.fromMillis(this.state.startTime)
      : DateTime.local();
    let startTime = startDate.toFormat("h:mm a");
    let endDate = !!this.state.endTime
      ? DateTime.fromMillis(this.state.endTime)
      : DateTime.local();
    let endTime = endDate.toFormat("h:mm a");
    // let duration = endDate.diff(startDate, ["hours", "minutes"]).toObject();
    let now = DateTime.local().toMillis();
    let gameInProgress = now > this.state.startTime && now < this.state.endTime;
    let userloggedIn = this.props.user && this.props.user.uid;
    let userCreatedGame =
      userloggedIn && this.props.user.uid === this.state.createdBy;
    let gameOver = this.state.endTime < now;
    return (
      <Grid container style={{ padding: "5px" }}>
        <If condition={this.state.loading}>
          <CircularProgress /> Loading Game
        </If>
        <If condition={!this.state.loading && !!this.state.id}>
          <Grid item xs={12}>
            <Typography
              onClick={e => {
                this.props.history.goBack();
              }}
              variant="subtitle1"
              style={{ lineHeight: "1.15em" }}
            >
              <IconButton>
                <ArrowBack />
              </IconButton>
              Back
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={8}>
                  <Grid item xs={12}>
                    <Typography
                      component="h6"
                      variant="h6"
                      style={{ lineHeight: "1.15em" }}
                    >
                      {this.state.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Grid container spacing={8}>
                      <Grid item xs={12}>
                        <CalendarIcon date={startDate.toJSDate()} />
                      </Grid>
                      <Grid item xs={10}>
                        <If condition={!this.state.deleted}>
                          <GameActions
                            showCalendar={!gameInProgress && !gameOver}
                            showDelete={userCreatedGame}
                            showShare
                            gameInProgress={gameInProgress}
                            game={{
                              id: this.state.id,
                              startTime: this.state.startTime,
                              endTime: this.state.endTime,
                              title: this.state.title,
                              location: this.state.location,
                              players: this.state.players,
                              totalPlayers: parseInt(this.state.totalPlayers),
                              limitJoinsToTotalPlayers: this.state
                                .limitJoinsToTotalPlayers
                            }}
                          />
                        </If>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={7}>
                    <Grid item xs={12}>
                      <If condition={gameOver}>
                        <Typography
                          variant="body1"
                          style={{ marginTop: ".5rem" }}
                        >
                          <b>Game is over</b>
                        </Typography>
                      </If>
                      <If condition={!!this.state.deleted}>
                        <Typography
                          variant="body1"
                          style={{ marginTop: ".5rem" }}
                        >
                          <b>GAME DELETED, will be removed from searches</b>
                        </Typography>
                      </If>
                      <If condition={gameInProgress}>
                        <Typography
                          variant="body1"
                          style={{ marginTop: ".5rem" }}
                        >
                          <b>Game In Progress</b>
                        </Typography>
                      </If>
                      <Typography
                        variant="body1"
                        style={{ marginTop: ".5rem" }}
                      >
                        {startTime} - {endTime}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ marginTop: ".5rem" }}
                      >
                        {this.state.location}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ marginTop: ".5rem" }}
                      >
                        <b>Level : </b> {this.state.level}
                      </Typography>
                      <If condition={!!this.state.type}>
                        <Typography
                          variant="body1"
                          style={{ marginTop: ".5rem" }}
                        >
                          <b>Format : </b>
                          {this.state.type}
                        </Typography>
                        <If condition={!!this.state.description}>
                          <Typography
                            variant="body1"
                            style={{ marginTop: ".5rem" }}
                          >
                            <b>Training description : </b>
                            {this.state.description}
                          </Typography>
                        </If>
                      </If>
                      <Typography
                        variant="body1"
                        style={{ marginTop: ".5rem" }}
                      >
                        <b>Location : </b>
                        {this.state.location}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ marginTop: ".5rem" }}
                      >
                        <b>Players Needed : </b>
                        {this.state.totalPlayers - currentPlayers.length > 0
                          ? this.state.totalPlayers - currentPlayers.length
                          : 0}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ marginTop: ".5rem" }}
                      >
                        <b>Created By</b>
                      </Typography>
                      <Player uid={this.state.createdBy} />
                      <If condition={!!this.state.private}>
                        <Typography
                          variant="body1"
                          style={{ marginTop: ".5rem" }}
                        >
                          <b>Private Game</b>
                        </Typography>
                      </If>
                      <If
                        condition={
                          this.state.invites && this.state.invites.length > 0
                        }
                      >
                        <Typography
                          variant="body1"
                          style={{ marginTop: ".5rem" }}
                        >
                          <b>Invited Players : </b>{" "}
                          {this.state.invites && this.state.invites.length}
                        </Typography>
                      </If>
                      <If
                        condition={currentPlayers && currentPlayers.length > 0}
                      >
                        <Typography
                          variant="body1"
                          style={{ marginTop: ".5rem" }}
                        >
                          <b>Players</b>
                        </Typography>
                        <List>
                          {currentPlayers.map(uid => {
                            return (
                              <ListItem key={uid}>
                                <Player showCheck uid={uid} />
                              </ListItem>
                            );
                          })}
                        </List>
                      </If>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </If>
      </Grid>
    );
  }
}

export default withAuthentication(withFirebase(GameDetails));
