import { capitalize } from "lodash";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker
} from "material-ui-pickers";
import { DateTime } from "luxon";
import {
  Fab,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  CardContent,
  Card,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import LuxonUtils from "@date-io/luxon";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import React from "react";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { isValidPhoneNumber, formatPhoneNumber } from "../../utils/contact";
import { withProfile } from "../Account";
import { styles } from "../../utils";
import If from "../If";
import withCommonUi from "../withCommonUi";
import { withFirebase } from "../Firebase";
import ButtonWithLoading from "../ButtonWithLoading";

const LOCATIONS = {
  SOUTH_SIDE: "HB Pier South Side",
  NORTH_SIDE: "HB Pier North Side",
  NEWLAND: "Newland",
  OTHER: {
    value: "Other",
    display: "User Defined Location"
  }
};

function autoStartTime(
  date = DateTime.local().plus({ hour: 12 }),
  playingHours = false
) {
  let newDate = date;
  if (DateTime.isDateTime(date)) {
    let { minute, hour, ...restOfDate } = date.toObject();

    // round to nearest 30 min
    let roundTo = 60 / 4; // ever 15 min
    let multiple = Math.round(minute / roundTo);
    minute = roundTo * multiple;

    if (minute === 60) {
      if (hour === 12) {
        hour = 1;
        minute = 0;
      } else {
        hour += 1;
        minute = 0;
      }
    }

    if (playingHours) {
      if (hour < 6) {
        hour = 6;
        minute = 0;
      } else if (hour > 18) {
        hour = 18;
        minute = 0;
      }
    }

    newDate = DateTime.fromObject({ minute, hour, ...restOfDate });
  }

  return newDate;
}

const GAME_TYPES = {
  GAMES: "Games",
  KOB: "KOB - King of Beach",
  SET: "Set - Best of 3",
  TRAINING: "Training"
};

const INITIAL_STATE = {
  createdUserAutoJoin: true,
  dateError: false,
  description: "",
  duration: 120,
  error: null,
  gender: "mens",
  type: GAME_TYPES.GAMES,
  level: "competitive",
  startTime: autoStartTime(DateTime.local().plus({ hour: 12 }), true),
  title: "",
  totalPlayers: 4,
  insertedTitle: false,
  saving: false,
  location: "HB Pier South Side",
  locationOther: "",
  friendToInvite: "",
  invites: [],
  private: false,
  limitJoinsToTotalPlayers: true
};

class CreateGames extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  static propTypes = {
    user: PropTypes.shape({
      uid: PropTypes.string.isRequired
    })
  };

  handleDateChange = date => {
    let newDate = autoStartTime(date);

    if (newDate.toMillis() > DateTime.local().toMillis()) {
      this.setState({ startTime: newDate });
    } else {
      this.setState({ dateError: true });
    }
  };
  getGameTitle = () => {
    let title = this.state.title;
    if (!this.state.insertedTitle) {
      // generate title
      let date = this.state.startTime;
      let day = date.toFormat("ccc");
      let gender = this.state.gender;
      let timeOfDayNumber = date.toFormat("H");
      let timeOfDay = "Early Morning";
      let level = this.state.level;
      if (timeOfDayNumber > 9 && timeOfDayNumber < 12) {
        timeOfDay = "Morning";
      } else if (timeOfDayNumber >= 12) {
        timeOfDay = "Afternoon";
      } else if (timeOfDayNumber >= 15) {
        timeOfDay = "Late Afternoon";
      }
      let type = this.state.type;
      title = capitalize(`${day} ${timeOfDay} ${gender} ${level} ${type}`);
    }
    return title;
  };
  onChange = event => {
    let name = event.target.name;
    var newState = {};
    let val = event.target.value;
    if (name === "title") {
      newState.insertedTitle = true;
      newState.title = val;
    }

    if (name === "level") {
      if (val === "beginner" || val === "fun") {
        newState["location"] = LOCATIONS.NORTH_SIDE;
      }
    }

    this.setState({
      [name]: val,
      ...newState
    });
  };
  shapeGame = () => {
    return {
      endTime:
        this.state.startTime.toMillis() + this.state.duration * 60 * 1000,
      level: this.state.level,
      type: this.state.type,
      description: this.state.description,
      limitJoinsToTotalPlayers: this.state.limitJoinsToTotalPlayers,
      players: this.state.createdUserAutoJoin
        ? { [this.props.user.uid]: true }
        : {},
      createdTs: DateTime.local().toMillis(),
      startTime: this.state.startTime.toMillis(),
      totalPlayers: parseInt(this.state.totalPlayers),
      title: this.getGameTitle(),
      createdBy: this.props.user.uid,
      gender: this.state.gender,
      location:
        this.state.location === "other"
          ? this.state.locationOther
          : this.state.location,
      invites:
        !!this.state.invites &&
        this.state.invites.map(phoneNumber => {
          return {
            type: "phone",
            value: this.formatPhoneNumber(phoneNumber)
          };
        }),
      private: this.state.private
    };
  };

  formatPhoneNumber = rawPhoneNumber => {
    let formattedPhoneNumber = rawPhoneNumber;
    let strippedValue = rawPhoneNumber.replace(/\D/g, "");
    if (rawPhoneNumber.indexOf("+1") !== 0) {
      // check for valid twillio format or add +1 to the begining
      formattedPhoneNumber = "+1" + strippedValue;
    } else {
      if (strippedValue.length === 10) {
        formattedPhoneNumber = "+1" + strippedValue;
      } else {
        formattedPhoneNumber = "+" + strippedValue;
      }
    }

    return formattedPhoneNumber;
  };

  onInviteAddClick = e => {
    let invites = [...this.state.invites];
    let formattedPhoneNumber = formatPhoneNumber(this.state.friendToInvite);
    if (invites.indexOf(formattedPhoneNumber) === -1) {
      invites.push(formattedPhoneNumber);
    }

    this.setState({
      invites,
      friendToInvite: "" // empty text field
    });
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState(
      {
        saving: true
      },
      () => {
        let gameData = this.shapeGame();
        this.props.dialog(
          withSaveGameDialogContent(
            saveGame => {
              if (!!saveGame) {
                this.props.firebase
                  .createGame(gameData, this.props.user)
                  .then(result => {
                    setTimeout(() => {
                      this.props.history.push(`game/${result.newGameKey}`);
                    }, 1000);
                  })
                  .catch(e => {
                    this.setState({
                      error: "Unable to save Game Data",
                      saving: false
                    });
                  });
              } else {
                this.setState({
                  saving: false
                });
              }
            },
            { title: gameData.title, invites: gameData.invites }
          ),
          (event, reason) => {
            if (reason !== "saving") {
              this.setState({
                saving: false
              });
            }
          }
        );
      }
    );
  };
  render() {
    let { classes } = this.props;
    if (this.state.saving) {
      return (
        <Grid container className={classes.spinner} justify="center">
          <CircularProgress color="secondary" />
          <Typography variant="h6">Saving Game</Typography>
        </Grid>
      );
    }
    const title = this.getGameTitle();
    const description = this.state.description;
    // remove players down 1 if created user is planning on joining
    const neededInvites = this.state.createdUserAutoJoin
      ? this.state.totalPlayers - 1
      : this.state.totalPlayers;
    const correctNumberOfInvites =
      this.state.private &&
      this.state.invites &&
      this.state.invites.length < neededInvites;
    const isInvalid = correctNumberOfInvites;
    const privateLabel =
      "Private Game - Won't show up in search. Only invited / privately shared users will see the game.";
    const publicLabel =
      "Public Game - Shows up in search. All users will be able to search for & join this game";

    return (
      <form onSubmit={this.onSubmit}>
        <Grid container className={classes.root} justify="center">
          <If condition={!!this.state.saving}>
            <Grid item xs={2}>
              <CircularProgress />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom align="center">
                Saving
              </Typography>
            </Grid>
          </If>
          <Grid item xs={12}>
            <Typography
              onClick={e => {
                this.props.history.goBack();
              }}
              variant="subtitle1"
              style={{ lineHeight: "1.15em" }}
            >
              <IconButton>
                <ArrowBack />
              </IconButton>
              Back
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Create Game</Typography>
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <DatePicker
                      className={classes.formControl}
                      style={{ maxWidth: "45%" }}
                      margin="normal"
                      label="Date"
                      minDate={DateTime.local().toJSDate()}
                      value={this.state.startTime.toJSDate()}
                      onChange={this.handleDateChange}
                    />
                    <TimePicker
                      style={{ maxWidth: "45%" }}
                      className={classes.formControl}
                      margin="normal"
                      label="Time"
                      minDate={DateTime.local().toJSDate()}
                      value={this.state.startTime.toJSDate()}
                      onChange={this.handleDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} margin="normal">
                    <InputLabel htmlFor="type-simple">Format</InputLabel>
                    <Select
                      value={this.state.type}
                      onChange={this.onChange}
                      inputProps={{
                        name: "type",
                        id: "type-simple"
                      }}
                    >
                      {Object.keys(GAME_TYPES).map(type => {
                        return (
                          <MenuItem key={type} value={GAME_TYPES[type]}>
                            {GAME_TYPES[type]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.formControl} margin="normal">
                    <InputLabel htmlFor="level-simple">
                      Level of play
                    </InputLabel>
                    <Select
                      value={this.state.level}
                      onChange={this.onChange}
                      inputProps={{
                        name: "level",
                        id: "level-simple"
                      }}
                    >
                      <MenuItem value={"fun"}>Fun</MenuItem>
                      <MenuItem value={"beginner"}>Beginner</MenuItem>
                      <MenuItem value={"competitive"}>Competitive</MenuItem>
                      <MenuItem value={"advanced"}>Advanced</MenuItem>
                      <MenuItem value={"pro"}>Pro</MenuItem>
                    </Select>
                  </FormControl>{" "}
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <FormControl
                      className={classes.formControl}
                      margin="normal"
                    >
                      <InputLabel htmlFor="gender-simple">Gender</InputLabel>
                      <Select
                        value={this.state.gender}
                        onChange={this.onChange}
                        inputProps={{
                          name: "gender",
                          id: "gender-simple"
                        }}
                      >
                        <MenuItem value={"mens"}>Mens</MenuItem>
                        <MenuItem value={"womens"}>Womens</MenuItem>
                        <MenuItem value={"coed"}>Coed</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.formControl}
                      margin="normal"
                    >
                      <InputLabel htmlFor="duration-simple">
                        Duration
                      </InputLabel>
                      <Select
                        value={this.state.duration}
                        onChange={this.onChange}
                        inputProps={{
                          name: "duration",
                          id: "duration-simple"
                        }}
                      >
                        <MenuItem value={"60"}>1 hour</MenuItem>
                        <MenuItem value={"90"}>1.5 hours</MenuItem>
                        <MenuItem value={"120"}>2 hours</MenuItem>
                        <MenuItem value={"150"}>2.5 hours</MenuItem>
                        <MenuItem value={"180"}>3 hours</MenuItem>
                        <MenuItem value={"210"}>3.5 hours</MenuItem>
                        <MenuItem value={"240"}>4 hours</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.formControl}
                      margin="normal"
                    >
                      <InputLabel htmlFor="totalPlayers-simple">
                        # Players
                      </InputLabel>
                      <Select
                        value={this.state.totalPlayers}
                        onChange={this.onChange}
                        inputProps={{
                          name: "totalPlayers",
                          id: "totalPlayers-simple"
                        }}
                      >
                        <MenuItem value={"2"}>2</MenuItem>
                        <MenuItem value={"3"}>3</MenuItem>
                        <MenuItem value={"4"}>4</MenuItem>
                        <MenuItem value={"5"}>5</MenuItem>
                        <MenuItem value={"6"}>6</MenuItem>
                        <MenuItem value={"7"}>7</MenuItem>
                        <MenuItem value={"8"}>8</MenuItem>
                        <MenuItem value={"9"}>9</MenuItem>
                        <MenuItem value={"10"}>10</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      margin="none"
                    >
                      <TextField
                        id="title"
                        label="Title"
                        name="title"
                        value={title}
                        onChange={this.onChange}
                        margin="normal"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify="space-between" alignItems="flex-end">
                    <Grid item xs={12} className={classes.formControl}>
                      <InputLabel htmlFor="location-simple">
                        Location
                      </InputLabel>
                      <Select
                        fullWidth
                        value={this.state.location}
                        onChange={this.onChange}
                        inputProps={{
                          name: "location",
                          id: "location-simple"
                        }}
                      >
                        {Object.keys(LOCATIONS).map(key => {
                          let location = LOCATIONS[key];
                          let value =
                            typeof location === "string"
                              ? location
                              : location.value;
                          let display =
                            typeof location === "string"
                              ? location
                              : location.display;
                          return (
                            <MenuItem key={key} value={value}>
                              {display}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <If condition={this.state.location === "Other"}>
                      <Grid item xs={12} className={classes.formControl}>
                        >
                        <TextField
                          id="location-other"
                          name="locationOther"
                          label="Enter New Location"
                          value={this.state.locationOther}
                          onChange={this.onChange}
                          margin="normal"
                          fullWidth
                        />
                      </Grid>
                    </If>
                  </Grid>
                </Grid>
                <If condition={this.state.type === GAME_TYPES.TRAINING}>
                  <Grid item xs={12}>
                    <Grid container>
                      <FormControl
                        className={classes.formControl}
                        fullWidth
                        margin="none"
                      >
                        <TextField
                          multiline
                          id="description"
                          label="Training Description"
                          placeholder="Defensive blocking, transition setting ..."
                          name="description"
                          value={description}
                          onChange={this.onChange}
                          margin="normal"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </If>
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="flex-end"
                    className={classes.formControl}
                  >
                    <Grid item xs={6}>
                      <TextField
                        id="friendToInvite"
                        label="Invite Friends with #"
                        name="friendToInvite"
                        placeholder="XXX.XXX.XXXX"
                        error={
                          (this.state.friendToInvite !== "" &&
                            !isValidPhoneNumber(this.state.friendToInvite)) ||
                          correctNumberOfInvites
                        }
                        helperText={
                          correctNumberOfInvites
                            ? `Need to invite at least ${neededInvites} player(s) for private games`
                            : null
                        }
                        value={this.state.friendToInvite}
                        fullWidth
                        onChange={this.onChange}
                        margin="normal"
                        inputProps={{
                          autoComplete: "off",
                          ref: element => (this.inviteField = element),
                          onKeyPress: e => {
                            if (e.charCode === 13) {
                              if (
                                isValidPhoneNumber(this.state.friendToInvite)
                              ) {
                                this.onInviteAddClick();
                                this.inviteField.focus();
                              }
                              e.preventDefault();
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container justify="center">
                        <Grid item xs={3}>
                          <Fab
                            color="secondary"
                            size="small"
                            onClick={this.onInviteAddClick}
                            disabled={
                              !isValidPhoneNumber(this.state.friendToInvite)
                            }
                            className={classes.button}
                            aria-label="Delete"
                          >
                            <AddIcon />
                          </Fab>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <If condition={this.state.invites.length > 0}>
                  <Grid item xs={9}>
                    <List>
                      {this.state.invites.map((inviteVal, index) => {
                        return (
                          <ListItem key={inviteVal}>
                            <ListItemText>{inviteVal}</ListItemText>
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={e => {
                                  let invites = [...this.state.invites];
                                  invites.splice(index, 1);
                                  this.setState({
                                    invites
                                  });
                                }}
                                aria-label="Delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                </If>
                <If condition={this.state.invites.length === 0}>
                  <Grid item xs={12}>
                    <FormControl
                      className={classes.formControl}
                      margin="normal"
                    >
                      <Grid container spacing={8}>
                        <Grid item xs={6}>
                          <Button
                            onClick={e => {
                              this.props.snackbar(
                                "Smart Invite Coming soon",
                                5000
                              );
                              this.props.feature({
                                name: "smartInvite",
                                context: "CreateGame",
                                uid: this.props.user && this.props.user.uid
                              });
                            }}
                            variant="outlined"
                          >
                            Smart Invite
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="caption">
                            Find players that fit game criteria and invite them
                          </Typography>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </If>
                <Grid item xs={12}>
                  <Grid container>
                    <Typography variant="caption">Game Settings</Typography>
                    <FormControlLabel
                      className={classes.formControl}
                      control={
                        <Switch
                          checked={this.state.private}
                          onChange={e => {
                            this.setState({
                              private: e.target.checked
                            });
                          }}
                          value="private"
                        />
                      }
                      label={this.state.private ? privateLabel : publicLabel}
                    />
                    <FormControlLabel
                      className={classes.formControl}
                      control={
                        <Switch
                          checked={this.state.limitJoinsToTotalPlayers}
                          onChange={e => {
                            this.setState({
                              limitJoinsToTotalPlayers: e.target.checked
                            });
                          }}
                          value="limitJoinsToTotalPlayers"
                        />
                      }
                      label={
                        this.state.limitJoinsToTotalPlayers
                          ? `Only Allow ${
                              this.state.totalPlayers
                            } players to join game.`
                          : "Any amount of players can join game before start time."
                      }
                    />
                    <FormControlLabel
                      className={classes.formControl}
                      control={
                        <Switch
                          checked={this.state.createdUserAutoJoin}
                          onChange={e => {
                            this.setState({
                              createdUserAutoJoin: e.target.checked
                            });
                          }}
                          value="createdUserAutoJoin"
                        />
                      }
                      label={
                        this.state.createdUserAutoJoin
                          ? `Automatically join this game as a player`
                          : "Create game without joining as a player"
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "3rem" }}>
                  <Button
                    fullWidth
                    disabled={isInvalid}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Schedule
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {this.state.error && <p>{this.state.error.message}</p>}
        </Grid>
      </form>
    );
  }
}

const condition = (profile, authUser) => !!authUser;

export default withStyles(styles)(
  withProfile(condition)(withCommonUi(CreateGames))
);

const withSaveGameDialogContent = (confirmCallback, passThroughProps) => {
  return handleDialogClose => {
    return withProfile((profile, user) => true)(
      withFirebase(props => {
        return (
          <SaveGameDialogComponent
            {...passThroughProps}
            {...props}
            confirmCallback={save => {
              confirmCallback(save);
              handleDialogClose(null, "saving");
            }}
            handleDialogClose={handleDialogClose}
          />
        );
      })
    );
  };
};

class SaveGameDialogComponent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <DialogTitle id="alert-dialog-title">Confirm Game Details</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>{this.props.title}</b>
            <If condition={this.props.invites && this.props.invites.length > 0}>
              <Typography variant="body1">
                Saving this game will send out text notification invite(s) to{" "}
                <b>{this.props.invites.length}</b> player(s)
                <List>
                  {this.props.invites.map(invite => {
                    return <ListItem>{invite.value}</ListItem>;
                  })}
                </List>
              </Typography>
            </If>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={e => {
              this.props.confirmCallback();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <ButtonWithLoading
            onClick={loadingToggle => {
              loadingToggle(1000).then(() => {
                this.props.confirmCallback(true);
              });
            }}
            color="secondary"
            variant="contained"
            autoFocus
          >
            Save Game
          </ButtonWithLoading>
        </DialogActions>
      </React.Fragment>
    );
  }
}
