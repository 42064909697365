import React from "react";
import { Snackbar, Dialog } from "@material-ui/core";
import If from "../If";
import { withFirebase } from "../Firebase";

const withCommonUi = Component => {
  class WithCommonUiComponent extends React.Component {
    state = {
      snackBar: {
        open: false,
        message: "",
        props: {
          anchorOrigin: { vertical: "top", horizontal: "center" }
        }
      },
      dialog: {
        content: () => () => "",
        open: false
      }
    };

    handleSnackBarClose = () => {
      this.setState({
        snackBar: {
          open: false,
          message: "",
          props: {
            anchorOrigin: { vertical: "top", horizontal: "center" }
          }
        }
      });
    };

    openSnackbar = (message, duration, props) => {
      this.setState({
        snackBar: {
          open: true,
          message,
          props: {
            ...this.state.snackBar.props,
            ...props,
            autoHideDuration: !!duration ? duration : null
          }
        }
      });
    };

    requestFeature = feature => {
      if (typeof feature === "string") {
        this.props.firebase.feature({
          name: feature,
          uid: this.props.user.uid
        });
      } else {
        this.props.firebase.feature(feature);
      }
    };

    openDialog = (content, closeCallback) => {
      this.setState({
        dialog: {
          open: true,
          content,
          closeCallback
        }
      });
    };

    handleDialogClose = (event, reason) => {
      if (this.state.dialog && !!this.state.dialog.closeCallback) {
        if (typeof this.state.dialog.closeCallback === "function") {
          this.state.dialog.closeCallback(event, reason);
        } else {
          throw new Error("Dialog.closeCallback isnt a function");
        }
      }
      this.setState({
        dialog: {
          open: false,
          content: () => () => ""
        }
      });
    };

    render() {
      let DialogContent = this.state.dialog.content(this.handleDialogClose);
      return (
        <React.Fragment>
          <If condition={this.state.snackBar.open}>
            <Snackbar
              {...this.state.snackBar.props}
              open={!!this.state.snackBar.open}
              onClose={this.handleSnackBarClose}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={
                <span id="message-id">{this.state.snackBar.message}</span>
              }
            />
          </If>
          <If condition={this.state.dialog.open}>
            <Dialog
              open={!!this.state.dialog.open}
              onClose={this.handleDialogClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent />
            </Dialog>
          </If>
          <Component
            {...this.props}
            feature={this.requestFeature}
            snackbar={this.openSnackbar}
            dialog={this.openDialog}
            user={this.props.user}
          />
        </React.Fragment>
      );
    }
  }

  return withFirebase(WithCommonUiComponent);
};

export default withCommonUi;
