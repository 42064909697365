import React from "react";

import { withAuthorization } from "../Session";
import { MyGames } from "../Games";
import * as ROUTES from "../../constants/routes";
import { Grid, withStyles } from "@material-ui/core";
import styled from "styled-components";
import { styles } from "../../utils";

const BottomBarOffset = styled.div`
  height: 105px;
  padding: 0;
  margin: 0;
`;

const HomePage = props => {
  const { classes } = props;
  return (
    <Grid
      container
      className={classes.root}
      spacing={8}
      alignContent="center"
      justify="center"
    >
      <MyGames
        user={props.user}
        onCreateGame={e => {
          props.history.push(ROUTES.CREATE);
        }}
      />
      <Grid item>
        <BottomBarOffset />
      </Grid>
    </Grid>
  );
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withStyles(styles)(HomePage));
