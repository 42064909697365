import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/browser";

import Navigation from "./components/Navigation";
import LandingPage from "./components/Landing";
import SearchPage from "./components/Search";
import { CreateGame as CreateGamePage } from "./components/Games";
import SignUpPage from "./components/SignUp";
import SignInPage from "./components/SignIn";
import PasswordForgetPage from "./components/PasswordForget";
import HomePage from "./components/Home";
import AccountPage from "./components/Account";
import AdminPage from "./components/Admin";
import CssBaseline from "@material-ui/core/CssBaseline";

import * as ROUTES from "./constants/routes";
import { withAuthentication } from "./components/Session";
import GameDetails from "./components/Games/GameDetails";
import { Grid, Button, Card, CardContent, Typography } from "@material-ui/core";
import "./App.css";
import TermsPage from "./components/Terms";
import PrivacyPage from "./components/Privacy";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#a4a4a4",
      main: "#757575",
      dark: "#494949"
    },
    secondary: {
      light: "#ffffa8",
      main: "#fff176",
      dark: "#cabf45"
    },
    disabled: {
      main: "#ccc"
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    If: {
      width: "100%"
    }
  },
  props: {
    If: {
      style: {
        width: "100%"
      }
    }
  }
});

Sentry.init({
  dsn: "https://675ced8ef18247f58758c2f957b65af0@sentry.io/1402549",
  environment: process.env.REACT_APP_ENVIRONMENT
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }
  render() {
    if (this.state.error) {
      return (
        <Grid
          container
          style={{ padding: "5px", height: "100vh" }}
          justify="center"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h5">HBVolleyball Error</Typography>
                <p>
                  Sorry, Something went wrong while trying to process your last
                  action. Trying refreshing the page and if that doesnt work
                  report the feedback by clicking the button below.
                </p>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    Sentry.showReportDialog();
                  }}
                >
                  Report feedback
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      );
    }
    return this.props.children;
  }
}

const App = props => {
  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <Navigation history={props.history} location={props.location} />
          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route path={ROUTES.GAME_DETAILS} component={GameDetails} />
          <Route path={ROUTES.SEARCH} component={SearchPage} />
          <Route path={ROUTES.CREATE} component={CreateGamePage} />
          <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
          <Route path={ROUTES.HOME} component={HomePage} />
          <Route path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route path={ROUTES.ADMIN} component={AdminPage} />
          <Route path={ROUTES.TERMS} component={TermsPage} />
          <Route path={ROUTES.PRIVACY} component={PrivacyPage} />
        </ErrorBoundary>
      </MuiThemeProvider>
    </Router>
  );
};

export default withAuthentication(App);
