import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import TextField from "@material-ui/core/TextField";

import { SignUpLink } from "../SignUp";
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import {
  Grid,
  Typography,
  withStyles,
  Button,
  IconButton,
  Card,
  CardContent
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import SvgIcon from "@material-ui/core/SvgIcon";

import { styles } from "../../utils";
import { withAuthentication } from "../Session";
import If from "../If";

const SignInPage = props => (
  <Grid container className={props.classes.root} alignItems="center">
    <Grid item xs={12}>
      <Typography
        onClick={e => {
          props.history.goBack();
        }}
        variant="subtitle1"
        style={{ lineHeight: "1.15em" }}
      >
        <IconButton>
          <ArrowBack />
        </IconButton>
        Back
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Card style={{ display: "flex", justifyContent: "space-between" }}>
        <Grid item xs={12}>
          <CardContent>
            <Grid item xs={12}>
              <Typography variant="h5">Login</Typography>
            </Grid>
            <Grid item xs={12}>
              <SignInForm />
            </Grid>
            <Grid item xs={12}>
              <PasswordForgetLink />
            </Grid>
            <Grid item xs={12}>
              <SignUpLink />
            </Grid>
          </CardContent>
        </Grid>
      </Card>
    </Grid>
  </Grid>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  redirectUrl: ROUTES.HOME
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    let searchParams = new URLSearchParams(this.props.location.search);

    if (searchParams.has("redirectUrl")) {
      let redirectUrl = decodeURI(searchParams.get("redirectUrl"));
      this.setState({
        redirectUrl
      });
    }
  }

  componentWillUpdate() {
    if (
      !!this.props.user &&
      !!this.props.user.uid &&
      this.state.redirectUrl !== ""
    ) {
      this.props.history.push(INITIAL_STATE.redirectUrl);
    }
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.props.history.push(this.state.redirectUrl);
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({
          error: "Error logging in, please try again."
        });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <Grid container justify="center" direction="column">
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <TextField
              id="email"
              name="email"
              label="Email Address"
              value={email}
              onChange={this.onChange}
              margin="normal"
              fullWidth
              autoComplete="email"
            />
          </Grid>
          <If condition={!!email}>
            <Grid item xs={12} style={{ marginTop: "1rem" }}>
              <TextField
                id="email"
                name="password"
                label="Password"
                value={password}
                onChange={this.onChange}
                margin="normal"
                type="password"
                fullWidth
              />
            </Grid>
          </If>
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <Button
              variant="contained"
              color="secondary"
              disabled={isInvalid}
              type="submit"
              fullWidth
            >
              Login
            </Button>
          </Grid>
        </Grid>
        <Grid container justify="center" direction="column">
          <Grid item style={{ marginTop: "1rem" }}>
            <Typography variant="caption" align="center">
              or
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              onClick={e => {
                this.props.firebase
                  .loginWithFacebook()
                  .then(() => {
                    this.props.history.push(this.state.redirectUrl);
                    this.setState({ ...INITIAL_STATE });
                  })
                  .catch(error => {
                    this.setState({
                      error:
                        "Error logging in, try logging in with Email and password"
                    });
                  });
              }}
            >
              <SvgIcon
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 58 58"
                style={{ marginRight: "1rem" }}
              >
                <title>flogo-RGB-HEX-Blk-58</title>
                <path d="M54.8,0H3.2A3.2,3.2,0,0,0,0,3.2V54.8A3.2,3.2,0,0,0,3.2,58H31V35.57H23.45V26.79H31V20.33c0-7.49,4.58-11.57,11.26-11.57A64.2,64.2,0,0,1,49,9.1v7.83h-4.6c-3.64,0-4.35,1.72-4.35,4.26v5.59h8.7l-1.13,8.78H40V58H54.8A3.2,3.2,0,0,0,58,54.8V3.2A3.2,3.2,0,0,0,54.8,0Z" />
                <path
                  id="f"
                  className="cls-1"
                  style={{ fill: "#fff" }}
                  d="M40,58V35.57h7.57l1.13-8.78H40V21.2c0-2.54.71-4.26,4.35-4.26H49V9.1a64.2,64.2,0,0,0-6.75-.34C35.56,8.76,31,12.84,31,20.33v6.46H23.45v8.78H31V58Z"
                />
              </SvgIcon>
              Login with Facebook
            </Button>
          </Grid>
        </Grid>

        {error && (
          <p>
            <b>{error}</b>
          </p>
        )}
      </form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
  withAuthentication
)(SignInFormBase);

export default withStyles(styles)(withRouter(SignInPage));

export { SignInForm };
