import React from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../Session";
import If from "../If";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import AddIcon from "@material-ui/icons/Add";
import HistoryIcon from "@material-ui/icons/History";
import Fab from "@material-ui/core/Fab";
import * as Sentry from "@sentry/browser";

import { withFirebase } from "../Firebase";
import withCommonUi from "../withCommonUi";
import { Grid } from "@material-ui/core";

const title =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? "DEV HB Volleyball"
    : "HB Volleyball";

const styles = {
  root: {
    flexGrow: 1,
    paddingTop: "56px"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  appBar: {
    top: "auto",
    bottom: 0
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "space-between"
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto"
  }
};

class MainAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      drawerOpen: false
    };
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleDrawer = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen
    });
  };

  shouldShowBottomBar = () => {
    let showOn = [ROUTES.HOME, ROUTES.LANDING, ROUTES.SEARCH, ROUTES.ACCOUNT];
    if (showOn.indexOf(this.props.location.pathname) !== -1) {
      return true;
    }
    return false;
  };

  render() {
    let { classes } = this.props;
    const open = Boolean(this.state.anchorEl);

    return (
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar>
            <Grid item xs={2}>
              <If condition={!!this.props.user}>
                <IconButton
                  aria-owns={open ? "menu-appbar" : undefined}
                  aria-haspopup="true"
                  aria-label="Menu"
                  className={classes.menuButton}
                  color="inherit"
                  id="menu-appbar"
                  open={open}
                  onClick={e => {
                    this.toggleDrawer();
                  }}
                  onClose={this.handleClose}
                >
                  <MenuIcon />
                </IconButton>
              </If>
            </Grid>
            <Grid item xs={8}>
              <Typography
                align="center"
                variant="h6"
                color="inherit"
                className={classes.grow}
              >
                <Link
                  color="secondary"
                  underline="none"
                  component={RouterLink}
                  to={ROUTES.LANDING}
                >
                  {title}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <If condition={!this.props.user}>
                <Button>
                  <Link
                    color="textSecondary"
                    underline="none"
                    component={RouterLink}
                    to={ROUTES.SIGN_IN}
                  >
                    Login
                  </Link>
                </Button>
              </If>
              <If condition={!!this.props.user}>
                <IconButton
                  color="inherit"
                  onClick={() => {
                    this.props.history.push(ROUTES.ACCOUNT);
                  }}
                >
                  {/* <Link
                    className={classes.menuButton}
                    component={RouterLink}
                    to={ROUTES.ACCOUNT}
                  > */}
                  <AccountCircle />
                  {/* </Link> */}
                </IconButton>
              </If>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer open={this.state.drawerOpen} onClose={this.toggleDrawer}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer}
            onKeyDown={this.toggleDrawer}
          >
            <List>
              {[
                { to: ROUTES.HOME, text: "Home" },
                { to: ROUTES.ACCOUNT, text: "My Account" },
                { to: ROUTES.CREATE, text: "Create Game" },
                { to: ROUTES.SEARCH, text: "Search Games" },
                { text: <SignOutButton key="sign-out-button" /> },
                { component: <hr key="hr" /> },
                {
                  onClick: () => {
                    Sentry.showReportDialog({
                      user: { email: this.props.user.email, name: "" }
                    });
                  },
                  text: "Give Feedback"
                }
              ].map((item, index) => {
                let text =
                  typeof item.text === "string" ? (
                    <ListItemText primary={item.text} />
                  ) : (
                    item.text
                  );

                return item.component ? (
                  item.component
                ) : (
                  <ListItem
                    button
                    key={index}
                    onClick={e => {
                      if (!!item.to) {
                        this.props.history.push(item.to);
                      }
                      if (
                        !!item.onClick &&
                        typeof item.onClick === "function"
                      ) {
                        item.onClick(e);
                      }
                    }}
                  >
                    <If condition={!!item.icon}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                    </If>
                    {text}
                  </ListItem>
                );
              })}
            </List>
          </div>
        </Drawer>
        <If condition={this.shouldShowBottomBar()}>
          <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                onClick={e => {
                  this.props.snackbar("History feature coming soon");
                  this.props.feature({
                    name: "History of games",
                    uid:
                      !!this.props.user && !!this.props.user.uid
                        ? this.props.user.uid
                        : "annonomous"
                  });
                }}
                color="inherit"
                aria-label="Open drawer"
              >
                <HistoryIcon />
              </IconButton>
              <Fab
                color="secondary"
                aria-label="Add"
                className={classes.fabButton}
                onClick={e => {
                  this.props.history.push(ROUTES.CREATE);
                }}
              >
                <AddIcon />
              </Fab>
            </Toolbar>
          </AppBar>
        </If>
      </div>
    );
  }
}

MainAppBar.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object.isRequired
};

const MainAppBarStyled = withStyles(styles)(
  withRouter(withCommonUi(MainAppBar))
);

const Navigation = ({ authUser }) => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <MainAppBarStyled user={authUser} /> : <MainAppBarStyled />
      }
    </AuthUserContext.Consumer>
  </div>
);

export const SignOutButton = withRouter(
  withFirebase(({ firebase, history }) => (
    <Button
      onClick={() => {
        firebase.doSignOut().then(() => {
          history.push(ROUTES.LANDING);
        });
      }}
    >
      Sign Out
    </Button>
  ))
);

export default Navigation;
