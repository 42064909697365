import React from "react";
import { withFirebase } from "../Firebase";
import { GameCard } from "../Games";
import {
  Grid,
  Typography,
  withStyles,
  CircularProgress
} from "@material-ui/core";
import { convertToArray } from "../../utils";
import styled from "styled-components";
import { styles } from "../../utils";
import If from "../If";

const BottomBarOffset = styled.div`
  height: 105px;
  padding: 0;
  margin: 0;
`;

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      games: [],
      loading: true
    };
  }

  componentDidMount() {
    this.props.firebase.upcomingGames().on("value", snapshot => {
      let games = convertToArray(snapshot.val());
      if (!!games) {
        games = games
          .filter(game => {
            return !game.deleted && !game.private;
          })
          .sort((a, b) => {
            return a.startTime - b.startTime;
          });
        this.setState({
          games,
          loading: false
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <If condition={!!this.state.loading}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={8}
          >
            <Grid item xs={12} style={{ marginTop: "3rem" }}>
              <CircularProgress align="center" />
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" variant="h6" gutterBottom>
                Loading Search
              </Typography>
            </Grid>
          </Grid>
        </If>
        <If condition={!this.state.loading}>
          <Grid container spacing={8} className={classes.root}>
            <Typography variant="h5">Search</Typography>
            {this.state.games.map(game => {
              return <GameCard key={game.id} game={game} />;
            })}
            <Grid item>
              <BottomBarOffset />
            </Grid>
          </Grid>
        </If>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withFirebase(SearchPage));
