import { DateTime } from "luxon";
import { Grid, Menu, Link } from "@material-ui/core";
import AddToCalendar from "@ej2586/react-add-to-calendar";
import Button from "@material-ui/core/Button";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuItem from "@material-ui/core/MenuItem";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import PropTypes from "prop-types";
import React from "react";
import SMSIcon from "@material-ui/icons/Sms";
import withCommonUi from "../withCommonUi";

import { withAuthentication } from "../Session";
import { LeaveGameBtn, DeleteGameBtn, JoinGameBtn } from ".";
import { getSmsLink } from "../../utils/contact";
import If from "../If";

class GameActionsComponent extends React.Component {
  state = {
    calendarAnchorElm: null
  };

  render() {
    let props = this.props;
    let userloggedIn = props.user && props.user.uid;
    let players = props.game.players || props.players;
    let playersArr = !!players
      ? Object.keys(players).filter(uid => {
          return !!players[uid];
        })
      : [];
    let userInPlayers =
      playersArr &&
      playersArr.length > 0 &&
      playersArr.find(uid => {
        return props.user ? uid === props.user.uid : false;
      }) !== undefined;
    let totalPlayers = parseInt(props.game.totalPlayers);
    let canJoin =
      (!userInPlayers && playersArr.length < totalPlayers) ||
      (!userInPlayers && !props.limitJoinsToTotalPlayers);
    let canLeave = !!userInPlayers && !!userloggedIn;
    let gameNeedsPlayers = playersArr && totalPlayers > playersArr.length;
    let now = DateTime.local().toMillis();
    let gameOver = props.game.endTime < now;
    let event;
    if (!!props.showCalendar) {
      let startTime = props.game.startTime ? props.game.startTime : now;
      let endTime = props.game.endTime ? props.game.endTime : now;

      event = {
        title: props.game.title,
        description: `View Details - https://hbvolleyball.com/game/${
          props.game.id
        }`,
        location: props.game.location,
        startTime,
        endTime
      };
    }

    return (
      <Grid container spacing={8} alignContent="center">
        <If condition={!!canJoin && !gameOver}>
          <Grid item xs={12}>
            <JoinGameBtn
              disabled={!canJoin || props.gameInProgress}
              variant="contained"
              gameid={props.game.id}
              fullWidth
            >
              Join <PlaylistAddIcon style={{ marginLeft: "1rem" }} />
            </JoinGameBtn>
          </Grid>
        </If>
        <If condition={!!canLeave && !gameOver}>
          <Grid item xs={12}>
            <LeaveGameBtn
              disabled={props.gameInProgress}
              variant="outlined"
              gameid={props.game.id}
              fullWidth
            />
          </Grid>
        </If>
        <If condition={!!props.showCalendar && !!props.game.title}>
          <Grid item xs={12}>
            <AddToCalendar
              dropdownClass="styled-drop-down"
              buttonTemplate={(props, toggleCallback) => {
                return (
                  <Grid className={props.buttonWrapperClass} container>
                    <Button
                      fullWidth
                      variant="outlined"
                      aria-owns={
                        this.state.calendarAnchorElm
                          ? "add-to-calendar-menu"
                          : undefined
                      }
                      // className={props.buttonClass}
                      aria-haspopup="true"
                      onClick={e => {
                        this.setState({
                          calendarAnchorElm: e.currentTarget
                        });
                        toggleCallback(e);
                      }}
                    >
                      Add
                      <CalendarTodayIcon style={{ marginLeft: "1rem" }} />
                    </Button>
                  </Grid>
                );
              }}
              dropdownTemplate={(items, className) => {
                return (
                  <Menu
                    className={className}
                    id="add-to-calendar-menu"
                    anchorEl={this.state.calendarAnchorElm}
                    open={!!this.state.calendarAnchorElm}
                    onClose={e => {
                      this.setState({ calendarAnchorElm: null });
                    }}
                  >
                    {items}
                  </Menu>
                );
              }}
              listItemTemplate={({ listItem, event, itemClick, url }) => {
                let currentItem = Object.keys(listItem)[0];
                let currentLabel = listItem[currentItem];
                return (
                  <MenuItem
                    key={currentLabel}
                    onClick={e => {
                      window.open(url, "_blank");
                    }}
                  >
                    {currentLabel}
                  </MenuItem>
                );
              }}
              event={event}
            />
          </Grid>
        </If>
        <If condition={gameNeedsPlayers || canJoin}>
          <If condition={props.showShare}>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                component={Link}
                fullWidth
                href={getSmsLink(
                  "",
                  encodeURI(
                    `Join Game - ${
                      this.props.game.title
                    }.\nhttp://hbvolleyball.com/game/${this.props.game.id}`
                  )
                )}
              >
                Share
                <SMSIcon style={{ marginLeft: "1rem" }} />
              </Button>
            </Grid>
          </If>
          {/* <ButtonWithLoading
            color={props.color ? props.color : "primary"}
            variant="outlined"
            onClick={loadingToggle => {
              loadingToggle(0);
              props.snackbar("Feature coming soon", 5000);
              props.feature({
                name: "invite",
                context: "GameActions",
                uid: props.user && props.user.uid
              });
            }}
          >
            Invite
          </ButtonWithLoading> */}
        </If>
        <If condition={props.showDelete && !props.gameInProgress && !gameOver}>
          <Grid item xs={12}>
            <DeleteGameBtn
              fullWidth
              color={props.color ? props.color : "primary"}
              variant="outlined"
              title={props.game.title}
              gameid={props.game.id}
            >
              Delete <DeleteIcon style={{ marginLeft: "1rem" }} />
            </DeleteGameBtn>
          </Grid>
        </If>
      </Grid>
    );
  }
}

GameActionsComponent.defaultProps = {
  showCalendar: false,
  showDelete: false,
  showShare: false
};

GameActionsComponent.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string
  }),
  game: PropTypes.shape({
    endTime: PropTypes.number,
    id: PropTypes.string.isRequired,
    location: PropTypes.string,
    player: PropTypes.array,
    startTime: PropTypes.number,
    title: PropTypes.string,
    totalPlayers: PropTypes.number
  }),
  gameInProgress: PropTypes.bool,
  showCalendar: PropTypes.bool,
  showDelete: PropTypes.bool,
  showShare: PropTypes.bool
};

export default withCommonUi(withAuthentication(GameActionsComponent));
