import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { RecentGames } from "../Games";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../utils";

import * as ROUTES from "../../constants/routes";

import { withAuthorization } from "../Session";
import styled from "styled-components";

const BottomBarOffset = styled.div`
  height: 105px;
  padding: 0;
  margin: 0;
`;

const LandingPage = props => {
  var currentHour = new Date().getHours(); // current time
  var actionType = "Today";
  if (currentHour > 19) {
    // after 7pm
    actionType = "Tomorrow Morning";
  } else if (currentHour > 11 && currentHour < 17) {
    actionType = "This Afternoon";
  }
  return (
    <Grid container className={props.classes.root} spacing={8}>
      <Grid item xs={12}>
        <Card>
          <Grid container>
            <Grid item xs={9}>
              <CardContent>
                <Typography component="h5" variant="h5">
                  Play {actionType}
                </Typography>
                <Button
                  onClick={e => {
                    props.history.push(ROUTES.SEARCH);
                  }}
                  size="small"
                  variant="outlined"
                >
                  Find Games
                </Button>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <RecentGames />
      <Grid item>
        <BottomBarOffset />
      </Grid>
    </Grid>
  );
};

const condition = authUser => !authUser;

export default withAuthorization(condition)(withStyles(styles)(LandingPage));
