import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import If from "../If";
import TimeAgo from "react-timeago";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import GroupIcon from "@material-ui/icons/Group";
import { convertToArray } from "../../utils";
import { CalendarIcon } from "react-calendar-icon";
import { withRouter } from "react-router-dom";
import withCommonUi from "../withCommonUi";
import { isValidPhoneNumber, formatPhoneNumber } from "../../utils/contact";

import * as ROUTES from "../../constants/routes";

import { DateTime } from "luxon";

import { withProfile } from "../Account";
import { withFirebase } from "../Firebase";
import {
  Grid,
  Switch,
  FormControlLabel,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { styles } from "../../utils";
import ButtonWithLoading from "../ButtonWithLoading";
import { withAuthentication } from "../Session";
import GameActionsComponent from "./GameActions";

import CreateGameComponent from "./CreateGame";

export const CreateGame = CreateGameComponent;

export const GameActions = withCommonUi(
  withAuthentication(GameActionsComponent)
);

GameActions.defaultProps = {
  gameInProgress: false,
  endTime: DateTime.local()
    .plus({ hour: 2 })
    .toMillis(),
  location: null,
  startTime: DateTime.local().toMillis(),
  showDelete: false,
  title: null
};

GameActions.propTypes = {
  gameInProgress: PropTypes.bool,
  endTime: PropTypes.number,
  location: PropTypes.string,
  startTime: PropTypes.number,
  showDelete: PropTypes.bool,
  title: PropTypes.string
};

class JoinGameDialogComponent extends React.Component {
  state = {
    phoneNumber: ""
  };

  render() {
    let notificationsOn =
      !!this.props.profile &&
      !!this.props.profile.notifications &&
      this.props.profile.notifications.fullGame;
    let phoneNumber = !!this.props.profile && this.props.profile.phoneNumber;
    return (
      <React.Fragment>
        <DialogTitle id="form-dialog-title">Join Game</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Joining a game means you are committing to show up at the scheduled
            time. Not showing up could negatively relect in future invites.
          </DialogContentText>
          <FormControlLabel
            control={
              <Switch
                checked={notificationsOn}
                onChange={e => {
                  let val = e.target.checked;
                  this.props.updateProfile({
                    notifications: {
                      fullGame: val
                    }
                  });
                  if (!val) {
                    this.setState({
                      phoneNumber: ""
                    });
                  }
                }}
                value="gameNotifications"
              />
            }
            label="Send notification when game is full"
          />
          <TextField
            disabled={!!phoneNumber || !notificationsOn}
            id="userPhoneNumber"
            label={!!phoneNumber ? phoneNumber : "Phone Number"}
            name="userPhoneNumber"
            value={this.state.phoneNumber}
            fullWidth
            margin="normal"
            error={
              this.state.phoneNumber !== "" &&
              !isValidPhoneNumber(this.state.phoneNumber)
            }
            onChange={e => {
              let val = e.target.value;
              this.setState({
                phoneNumber: val
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={e => {
              this.props.handleDialogClose();
              this.props.confirmCallback(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Grid item xs={6}>
            <ButtonWithLoading
              disabled={
                notificationsOn &&
                !phoneNumber &&
                !isValidPhoneNumber(this.state.phoneNumber)
              }
              variant="contained"
              onClick={loadingToggle => {
                if (this.state.phoneNumber) {
                  this.props
                    .updateProfile({
                      phoneNumber: formatPhoneNumber(this.state.phoneNumber)
                    })
                    .then(() => {
                      loadingToggle(1000).then(() => {
                        this.props.confirmCallback(true);
                      });
                    });
                } else {
                  loadingToggle(1000).then(() => {
                    this.props.confirmCallback(true);
                  });
                }
              }}
              color="secondary"
            >
              Join Game
            </ButtonWithLoading>
          </Grid>
        </DialogActions>
      </React.Fragment>
    );
  }
}

class DeleteGameDialogComponent extends React.Component {
  state = {
    phoneNumber: ""
  };

  render() {
    return (
      <React.Fragment>
        <DialogTitle id="alert-dialog-title">Delete Game</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete game <b>{this.props.title}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={e => {
              this.props.handleDialogClose();
              this.props.confirmCallback();
            }}
            color="primary"
          >
            cancel
          </Button>
          <Button
            onClick={e => {
              this.props.handleDialogClose();
              this.props.confirmCallback(true);
            }}
            color="secondary"
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}

const joinGameDialogContent = confirmCallback => {
  return handleDialogClose => {
    return withProfile((profile, user) => true)(
      withFirebase(props => {
        return (
          <JoinGameDialogComponent
            {...props}
            confirmCallback={e => {
              confirmCallback(true);
              handleDialogClose(null, "saving");
            }}
            handleDialogClose={handleDialogClose}
          />
        );
      })
    );
  };
};

const withDeleteGameDialogContent = (confirmCallback, passThroughProps) => {
  return handleDialogClose => {
    return withProfile((profile, user) => true)(
      withFirebase(props => {
        return (
          <DeleteGameDialogComponent
            {...passThroughProps}
            {...props}
            confirmCallback={confirmCallback}
            handleDialogClose={handleDialogClose}
          />
        );
      })
    );
  };
};

export const DeleteGameBtn = withCommonUi(
  withFirebase(
    withRouter(props => {
      let color = props.color ? props.color : "secondary";
      return (
        <ButtonWithLoading
          color={color}
          disabled={props.disabled}
          variant={props.variant}
          classes={props.classes}
          component={props.component}
          fullWidth={props.fullWidth}
          onClick={loadingToggle => {
            props.dialog(
              withDeleteGameDialogContent(
                deleteGame => {
                  if (deleteGame) {
                    loadingToggle(1000).then(() => {
                      props.firebase
                        .gameById(props.gameid)
                        .update({
                          deleted: true
                        })
                        .catch(result => {
                          if (result && !result.uid) {
                            props.history.push(ROUTES.HOME);
                          }
                        });
                    });
                  } else {
                    loadingToggle(0);
                  }
                },
                { title: props.title }
              )
            );
          }}
        >
          {props.children}
        </ButtonWithLoading>
      );
    })
  )
);

DeleteGameBtn.propTypes = {
  gameid: PropTypes.string,
  title: PropTypes.string
};

export const JoinGameBtn = withCommonUi(
  withFirebase(
    withRouter(props => {
      let color = props.color ? props.color : "secondary";
      return (
        <ButtonWithLoading
          color={color}
          disabled={props.disabled}
          variant={props.variant}
          classes={props.classes}
          component={props.component}
          fullWidth={props.fullWidth}
          onClick={loadingToggle => {
            props.dialog(
              joinGameDialogContent(joinGame => {
                if (joinGame) {
                  loadingToggle(1000).then(() => {
                    props.firebase.joinGame(props.gameid).catch(result => {
                      if (result && !result.uid) {
                        props.history.push(
                          ROUTES.SIGN_IN +
                            `?redirectUrl=${encodeURIComponent(
                              `/game/${props.gameid}`
                            )}`
                        );
                      }
                    });
                  });
                } else {
                  loadingToggle(0);
                }
              }),
              (event, reason) => {}
            );
          }}
        >
          {props.children}
        </ButtonWithLoading>
      );
    })
  )
);

export const LeaveGameBtn = withFirebase(props => {
  return (
    <ButtonWithLoading
      color={props.color ? props.color : "primary"}
      disabled={props.disabled}
      variant={props.variant}
      classes={props.classes}
      component={props.component}
      fullWidth={props.fullWidth}
      onClick={loadingToggle => {
        loadingToggle(1000).then(() => {
          props.firebase.leaveGame(props.gameid).then(results => {
            loadingToggle(2000);
          });
        });
      }}
    >
      Leave <RemoveCircleOutlineIcon style={{ marginLeft: "1rem" }} />
    </ButtonWithLoading>
  );
});

export const ViewGameBtn = withRouter(props => {
  return (
    <Button
      color={props.color ? props.color : "primary"}
      disabled={props.disabled}
      variant={props.variant}
      classes={props.classes}
      component={props.component}
      onClick={e => {
        props.history.push(`/game/${props.gameid}`);
      }}
    >
      View <VisibilityIcon style={{ marginLeft: "1rem" }} />
    </Button>
  );
});

export const GameCardSimple = props => {
  let currentPlayers = props.game.players
    ? convertToArray(props.game.players)
    : [];
  let playersNeeded = parseInt(props.game.totalPlayers) - currentPlayers.length;
  let date = DateTime.fromMillis(props.game.startTime);
  let startTime = date.toFormat("h:mm a");
  let dateString = date.toFormat("DD");
  let endTime = DateTime.fromMillis(props.game.endTime).toFormat("h:mm a");
  let now = DateTime.local().toMillis();
  let gameInProgress = now > props.game.startTime && now < props.game.endTime;
  return (
    <Card style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <CardContent>
          <Typography component="h6" variant="h6">
            {props.game.title} <small>{props.game.level}</small>
          </Typography>
          <If condition={playersNeeded > 0}>
            <p>{playersNeeded} players needed</p>
          </If>
          <p>
            {dateString} {startTime} - {endTime}
          </p>
          <Typography variant="body1">
            <TimeAgo date={props.game.createdTs} />
          </Typography>
          <GameActions
            gameInProgress={gameInProgress}
            players={currentPlayers}
            totalPlayers={parseInt(props.game.totalPlayers)}
            game={props.game}
          />
        </CardContent>
      </div>
    </Card>
  );
};

export const GameCardComponent = props => {
  let playersArr = !!props.game.players
    ? Object.keys(props.game.players).filter(uid => {
        return !!props.game.players[uid];
      })
    : [];
  let date = DateTime.fromMillis(props.game.startTime);
  let startTime = date.toFormat("h:mm a");
  // let endTime = DateTime.fromMillis(props.game.endTime).toFormat("h:mm a");
  let now = DateTime.local().toMillis();
  let gameInProgress = now > props.game.startTime && now < props.game.endTime;
  return (
    <Grid item xs={12}>
      <Card style={{ display: "flex", justifyContent: "space-between" }}>
        <CardContent>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Typography
                component="h6"
                variant="h6"
                style={{ lineHeight: "1.15em" }}
              >
                {props.game.title}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <CalendarIcon
                date={DateTime.fromMillis(props.game.startTime).toJSDate()}
              />
            </Grid>
            <Grid item xs={5}>
              <Grid item xs={12}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={8}
                >
                  <Grid item xs={3}>
                    <GroupIcon fontSize="large" style={{ padding: "6px" }} />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body1">
                      ({playersArr.length} / {parseInt(props.game.totalPlayers)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <AccessTimeIcon
                      color={gameInProgress ? "secondary" : "primary"}
                      fontSize="large"
                      style={{ padding: "6px" }}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body1">{startTime}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <GameActions
                    gameInProgress={gameInProgress}
                    totalPlayers={parseInt(props.game.totalPlayers)}
                    game={props.game}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ViewGameBtn variant="outlined" gameid={props.game.id} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export const GameCard = withAuthentication(GameCardComponent);

class RecentGamesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { recentGames: [], loading: true };
  }
  componentDidMount() {
    this.props.firebase.gamesRecent().then(recentGames => {
      this.setState({
        recentGames,
        loading: false
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <If condition={!!this.state.loading}>
          <Grid container justify="center">
            <Grid item>
              <Typography variant="h5" gutterBottom>
                <CircularProgress />
                Loading Recently Create Games
              </Typography>
            </Grid>
          </Grid>
        </If>
        <Typography variant="h5" gutterBottom>
          Recently Created Games
        </Typography>
        {this.state.recentGames.map(game => {
          return <GameCard key={game.id} game={game} />;
        })}
      </React.Fragment>
    );
  }
}

export const RecentGames = withFirebase(RecentGamesComponent);

class MyGamesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { games: [], loading: true };

    this.ref = null;
  }
  componentDidMount() {
    if (this.props.user && this.props.user.uid) {
      this.ref = this.props.firebase.games(this.props.user.uid);
      this.ref.on("value", snapshot => {
        let data = snapshot.val();
        if (data) {
          let games = this.props.firebase
            .convertToArray(data)
            .sort((a, b) => {
              return a.startTime - b.startTime;
            })
            .filter(game => {
              return (
                game.startTime >
                  DateTime.local()
                    .minus({ hour: 3 })
                    .toMillis() && !game.deleted
              );
            });
          this.setState({
            games,
            loading: false
          });
        } else {
          this.setState({ loading: false });
        }
      });
    }
  }
  componentWillUnmount() {
    this.ref.off();
  }
  render() {
    return (
      <React.Fragment>
        <If condition={!!this.state.loading}>
          <Typography variant="h5" gutterBottom>
            <CircularProgress />
            Loading Recently Create Games
          </Typography>
        </If>
        <If condition={this.state.games && this.state.games.length > 0}>
          <Grid container spacing={8}>
            <Grid item>
              <Typography variant="h5" gutterBottom>
                My upcoming games
              </Typography>
            </Grid>
            {this.state.games.map(game => {
              return <GameCard key={game.id} game={game} />;
            })}
          </Grid>
        </If>
        <If condition={!this.state.games || this.state.games.length === 0}>
          <Grid item xs={10}>
            <Typography variant="h6" align="center" gutterBottom>
              No Scheduled Games
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Button
              onClick={e => this.props.history.push(ROUTES.SEARCH)}
              variant="outlined"
              fullWidth
            >
              Search
            </Button>
            <Button
              onClick={e => {
                this.props.snackbar("Feature coming soon", 5000);
                this.props.feature("Find Users");
              }}
              variant="outlined"
              fullWidth
            >
              Find Players
            </Button>
          </Grid>
        </If>
      </React.Fragment>
    );
  }
}

export const MyGames = withStyles(styles)(
  withCommonUi(withRouter(MyGamesComponent))
);
