import * as app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { DateTime } from "luxon";
import { convertToArray } from "../../utils";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
  }

  loginWithFacebook = () => {
    return this.auth.signInWithPopup(this.facebookProvider).then(result => {
      if (
        result &&
        result.additionalUserInfo &&
        result.additionalUserInfo.isNewUser
      ) {
        return this.createUser({
          uid: result.user.uid,
          name: result.user.displayName,
          email: result.user.email
        }).then(() => {
          return result;
        });
      }
      return result;
    });
  };

  /**
   * create new user database object on signup
   * @param {Object} user
   * @param {string} user.uid
   * @param {string} user.email
   * @param {string} [user.phoneNumber]
   * @returns {Promise}
   */
  createUser = user => {
    return new Promise((resolve, reject) => {
      if (user && user.uid && user.email) {
        // check for uid before creating
        this.user(user.uid).once("value", snapshot => {
          let val = snapshot.val();
          if (!val) {
            // no value so create user
            this.user(user.uid)
              .set({
                name: user.name,
                email: user.email,
                phoneNumber: !!user.phoneNumber ? user.phoneNumber : "",
                createdAt: DateTime.local().toMillis(),
                updatedAt: DateTime.local().toMillis()
              })
              .then(snapshot => {
                resolve(snapshot);
              })
              .catch(reject);
          } else {
            reject("User is already created");
          }
        });
      } else {
        reject("Creating user requires uid & email");
      }
    });
  };

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref("users");

  publicGames = () =>
    this.db
      .ref("games")
      .orderByChild("private")
      .equalTo(false);

  _root = () => this.db.ref("/");
  _games = () => this.db.ref("games");

  _notifications = () => this.db.ref("notifications");

  _feature = () => this.db.ref("features");

  _usersGames = uid => this.db.ref(`usersGames/${uid}`);

  createGame = (gameData, userData) => {
    let newGameRef = this._games().push();
    let newGameKey = newGameRef.key;

    let updateGameData = {};
    updateGameData[`/games/${newGameKey}`] = gameData;

    if (gameData.invites && gameData.invites.length > 0) {
      gameData.invites.forEach((invite, index) => {
        let newNotificationRef = this._notifications().push();
        let newNotificationKey = newNotificationRef.key;

        //update invite key in games invites, if exists
        if (!!updateGameData[`/games/${newGameKey}`]) {
          gameData["invites"][index].notificationId = newNotificationKey;
          updateGameData[`/games/${newGameKey}`] = gameData;
        }

        // if text aren't being sent out check the type in the datbase on the invite
        if (invite.type === "phone") {
          updateGameData[`/notifications/${newNotificationKey}`] = {
            sent: false,
            type: "invite",
            data: {
              gameId: newGameKey,
              invitedByUid: userData.uid,
              phoneNumber: invite.value,
              title: gameData.title
            }
          };
        }
      });
    }

    // create usersGames node with createdBy
    let createdBy = gameData.createdBy;
    if (!!createdBy) {
      updateGameData[`/usersGames/${createdBy}/${newGameKey}`] = {
        title: gameData.title,
        gameId: newGameKey
      };
    }

    return this._root()
      .update(updateGameData)
      .then(result => {
        return {
          newGameKey,
          ...result
        };
      });
  };

  games = (uid = null) => {
    if (!uid) {
      return this._games();
    }
    return this._games()
      .orderByChild("createdBy")
      .equalTo(uid);
  };

  upcomingGames = (uid = null) => {
    if (!uid) {
      return this._games()
        .orderByChild("startTime")
        .startAt(
          DateTime.local()
            .minus({ hour: 2 })
            .toMillis()
        )
        .limitToFirst(20);
    }
  };

  feature = feature => {
    if (!!feature) {
      let p = new Promise((resolve, reject) => {
        try {
          this._feature().push(feature, snapshot => {
            resolve(snapshot);
          });
        } catch (e) {
          reject(e);
        }
      });
      return p;
    }

    return this._feature();
  };

  gameById = id => {
    if (!!id) {
      return this._games().child(id);
    }

    return Promise.reject(new Error("id for gameById is undefined"));
  };
  joinGame = gameid => {
    if (gameid && this.auth.currentUser && this.auth.currentUser.uid) {
      let newPlayerRef = this._games().child(`${gameid}/players`);
      return newPlayerRef.update({
        [this.auth.currentUser.uid]: true
      });
    }

    return Promise.reject(
      new Error(
        `Error trying to join game with gameid:${gameid}, user:${
          this.auth.currentUser.uid
        }`
      )
    );
  };
  leaveGame = gameid => {
    if (gameid && this.auth.currentUser && this.auth.currentUser.uid) {
      let p = new Promise((resolve, reject) => {
        this._games()
          .child(gameid)
          .child(`players/${this.auth.currentUser.uid}`)
          .set(false);
      });
      return p;
    }
    return Promise.reject({ uid: null });
  };

  gamesRecent = () => {
    return this.publicGames()
      .once("value")
      .then(snapshotRef => {
        let publicGameArr = convertToArray(snapshotRef.val());
        return publicGameArr
          .sort((a, b) => {
            return b.createdTs - a.createdTs;
          })
          .filter(game => {
            return game.endTime > DateTime.local().toMillis() && !game.deleted;
          })
          .slice(0, 3);
      });
  };

  convertToArray = convertToArray;
}

export default Firebase;
