import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import If from "../If";
import ArrowBack from "@material-ui/icons/ArrowBack";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import {
  Button,
  Grid,
  withStyles,
  Typography,
  List,
  ListItem,
  IconButton,
  Card,
  CardContent
} from "@material-ui/core";
import { styles } from "../../utils";
import {
  formatPhoneNumber,
  isValidPhoneNumber,
  isValidEmail
} from "../../utils/contact";

const SignUpPage = props => (
  <Grid container>
    <Grid item xs={12}>
      <Typography
        onClick={e => {
          props.history.goBack();
        }}
        variant="subtitle1"
        style={{ lineHeight: "1.15em" }}
      >
        <IconButton>
          <ArrowBack />
        </IconButton>
        Back
      </Typography>
    </Grid>
    <Card style={{ display: "flex", justifyContent: "space-between" }}>
      <CardContent>
        <Grid item xs={12}>
          <Typography variant="h5">Signup</Typography>
        </Grid>
        <SignUpForm />
      </CardContent>
    </Card>
  </Grid>
);

const INITIAL_STATE = {
  name: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  phoneNumber: "",
  error: null
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { name, email, passwordOne, phoneNumber } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.createUser({
          uid: authUser.user.uid,
          email,
          name,
          phoneNumber
        });
      })
      .then(authUser => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    let name = event.target.name;
    let val = event.target.value;
    let newState = { [name]: val };

    if (name === "phoneNumber" && isValidPhoneNumber(val)) {
      newState[name] = formatPhoneNumber(val);
    }
    this.setState(newState);
  };

  render() {
    const {
      name,
      email,
      passwordOne,
      passwordTwo,
      phoneNumber,
      error
    } = this.state;
    const passwordsMatch = passwordOne === passwordTwo;
    const isInvalid =
      !passwordsMatch || passwordOne === "" || email === "" || name === "";

    return (
      <Grid container xs="12" className={this.props.classes.root}>
        <form onSubmit={this.onSubmit}>
          <TextField
            name="name"
            value={name}
            label="Full Name"
            onChange={this.onChange}
            placeholder="Timbo HB"
            fullWidth
            margin="normal"
          />
          <TextField
            name="email"
            label="Email Address"
            value={email}
            error={email !== "" && !isValidEmail(email)}
            onChange={this.onChange}
            placeholder="name@email.com"
            fullWidth
            margin="normal"
          />
          <TextField
            name="phoneNumber"
            label="Phone Number (Optional)"
            value={phoneNumber}
            onChange={this.onChange}
            placeholder="XXX.XXX.XXXX"
            fullWidth
            margin="normal"
          />
          <TextField
            name="passwordOne"
            label="Password"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="XXXXXX"
            fullWidth
            margin="normal"
            error={!passwordsMatch}
          />
          <TextField
            name="passwordTwo"
            label="Confirm Password"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="XXXXXX"
            fullWidth
            margin="normal"
            error={!passwordsMatch}
          />
          <If condition={!passwordsMatch}>
            <List>
              <ListItem>Passwords don't match</ListItem>
            </List>
          </If>
          <Button
            variant="contained"
            color="secondary"
            disabled={isInvalid}
            type="submit"
            fullWidth
          >
            Sign Up
          </Button>

          {error && <p>{error.message}</p>}
        </form>
      </Grid>
    );
  }
}

const SignUpLink = () => (
  <React.Fragment>
    Don't have an account?{" "}
    <Link color="textSecondary" underline="always" to={ROUTES.SIGN_UP}>
      Sign Up
    </Link>
  </React.Fragment>
);

const SignUpForm = compose(
  withRouter,
  withFirebase,
  withStyles(styles)
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
