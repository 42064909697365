import React, { Component } from "react";
import Link from "@material-ui/core/Link";

import * as ROUTES from "../../constants/routes";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import { withFirebase } from "../Firebase";
import withCommonUi from "../withCommonUi";

const PasswordForgetPage = () => (
  <div>
    <h1>PasswordForget</h1>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: "",
  error: null
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE }, () => {
          this.props.snackbar("Password reset email has been sent", 6000);
        });
      })
      .catch(error => {
        this.setState({ error }, () => {
          this.props.snackbar(
            "Error sending password reset email has been sent",
            6000
          );
        });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email } = this.state;

    const isInvalid = email === "";

    return (
      <Grid item xs={12}>
        <form onSubmit={this.onSubmit}>
          <Typography align="center" variant="h6" color="inherit">
            Password Reset
          </Typography>
          <Grid item>
            <TextField
              id="email"
              label="Email"
              name="email"
              value={email}
              fullWidth
              onChange={this.onChange}
              margin="normal"
              placeholder="Email Address"
            />
            <Button disabled={isInvalid} type="submit">
              Reset My Password
            </Button>
          </Grid>
        </form>
      </Grid>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link color="textSecondary" underline="always" to={ROUTES.PASSWORD_FORGET}>
      Forgot Password?
    </Link>
  </p>
);

const PasswordForgetForm = withFirebase(withCommonUi(PasswordForgetFormBase));

export default PasswordForgetPage;

export { PasswordForgetForm, PasswordForgetLink };
