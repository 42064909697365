export const isValidEmail = mightBeEmail => {
  let val = mightBeEmail.trim();
  let isEmail =
    !!val &&
    val.search(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g
    ) !== -1;

  return isEmail;
};

export const isValidPhoneNumber = mightBePhoneNumber => {
  let isPhone =
    mightBePhoneNumber &&
    mightBePhoneNumber.search(
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g
    ) !== -1;

  return isPhone;
};

export const formatPhoneNumber = rawPhoneNumber => {
  let formattedPhoneNumber = rawPhoneNumber;
  let strippedValue = rawPhoneNumber.replace(/\D/g, "");
  if (rawPhoneNumber.indexOf("+1") !== 0) {
    // check for valid twillio format or add +1 to the begining
    formattedPhoneNumber = "+1" + strippedValue;
  } else {
    if (strippedValue.length === 10) {
      formattedPhoneNumber = "+1" + strippedValue;
    } else {
      formattedPhoneNumber = "+" + strippedValue;
    }
  }

  return formattedPhoneNumber;
};

export function getSmsLink(phone, body) {
  let url = `sms:`;
  if (!!phone) {
    url += phone;
  } else {
    url += ";";
  }
  if (!!body) {
    url += `${getSMSDivider()}body=${body}`;
  }
  return url;
}
function getSMSDivider() {
  let macPlatforms = ["iPhone", "MacIntel", "MacPPC", "Mac68K", "iPod", "iPad"];
  return !!macPlatforms.find(platForm => navigator.platform === platForm)
    ? "&"
    : "?";
}

export default {
  isValidEmail,
  isValidPhoneNumber,
  formatPhoneNumber,
  getSmsLink
};
