import React from "react";
import { withStyles, Button, CircularProgress } from "@material-ui/core";
import green from "@material-ui/core/colors/green";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  wrapper: {
    width: "100%",
    position: "relative"
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class ButtonWithLoading extends React.Component {
  state = {
    loading: false
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  toggleLoading = waitMillis => {
    return new Promise((resolve, reject) => {
      if (waitMillis) {
        this.timeout = setTimeout(() => {
          this.setState({
            loading: !this.state.loading
          });
          resolve();
        }, waitMillis);
      } else {
        this.setState({
          loading: !this.state.loading
        });
        resolve();
      }
    });
  };

  render() {
    let { classes, onClick, ...rest } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Button
            color={rest.color ? rest.color : "primary"}
            variant={rest.variant}
            component={rest.component}
            disabled={this.state.loading || this.props.disabled}
            fullWidth={this.props.fullWidth}
            onClick={e => {
              this.setState(
                {
                  loading: true
                },
                () => {
                  if (onClick) {
                    onClick(this.toggleLoading, e);
                  }
                }
              );
            }}
          >
            {this.props.children}
          </Button>
          {this.state.loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ButtonWithLoading);
